import { AxiosRequestConfig } from "axios";
import customAxios from "utils/request";

const CATEGORIES_ENDPOINT = "/app/categories?l=25000&p=1";
const COUNTRIES_ENPOINT = "/app/countries";
const CURRENCIES_ENPOINT = "app/currencies";
const CITIES_ENPOINT = "/app/cities";
const negotiate =
  "https://proxy-server-f0c4.onrender.com/markethub/negotiate?negotiateVersion=1";
const intradayChart =
  "https://apipubaws.tcbs.com.vn/stock-insight/v1/intraday/{symbol}/pv-ins?resolution=1";
const stockGlance =
  "https://solieu3.mediacdn.vn/ProxyHandler.ashx?RequestName=StockSymbolSlide&sym={symbols}";

export const getNegotiate = async (): Promise<any> => {
  const params: AxiosRequestConfig = {
    method: "POST",
    url: negotiate,
    headers: {
      accept: "*/*",

      "Content-Type": "text/plain",
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
  // .map((item: any) => {
  //   return { name: item?.name || item, id: item?.id || item?.name };
  // });
};
export const getStockGlance = async ({ symbols }): Promise<any> => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: stockGlance.replaceAll(
      "{symbols}",
      symbols.reduce(
        (prev, curr) => (prev.length === 0 ? curr : `${prev};${curr}`),
        ""
      )
    ),
    headers: {
      accept: "*/*",

      "Content-Type": "text/plain",
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
  // .map((item: any) => {
  //   return { name: item?.name || item, id: item?.id || item?.name };
  // });
};

export const getIntradayChart = async ({ symbol }): Promise<any> => {
  const params: AxiosRequestConfig = {
    method: "GET",
    url: intradayChart.replace("{symbol}", symbol),
    headers: {
      accept: "*/*",

      "Content-Type": "text/plain",
    },
  };
  const response = await customAxios.request({ ...params });
  console.log(response);
  return response.data.data;
  // .map((item: any) => {
  //   return { name: item?.name || item, id: item?.id || item?.name };
  // });
};
