import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "redux/configureStore";
// import { Provider } from 'react-redux';
// import { applyMiddleware, compose, createStore, Store } from 'redux';
// import serviceworkerregis
import App from "./app/App";
import "./index.css";
// import "./index.scss";
import ReactGA from "react-ga";
ReactGA.initialize("Your Unique ID");

export const history = createBrowserHistory();
export const store = configureStore(history);

const container = document.getElementById("root");
const root = createRoot(container!); // if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';
// import App from './app/App';
// import './index.scss';
// import configureStore from './redux/configureStore';
// import reportWebVitals from './reportWebVitals';

// export const history = createBrowserHistory();
// export const store = configureStore(history);
