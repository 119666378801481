interface State {
  value: any;
}

export const tokenKey = `${process.env.REACT_APP_BASENAME}.token`;
export const tokenRefresh = `${process.env.REACT_APP_BASENAME}.tokenRefresh`;
export const userKey = `${process.env.REACT_APP_BASENAME}.user`;

export const handleStorage = (name: string, value: any): void => {
  localStorage.setItem(name, JSON.stringify(value || {}));
};

export const deleteItemStorage = (name: string): void => {
  localStorage.removeItem(name);
};

export const clearStorage = (): void => {
  localStorage.clear();
};

export const getStorage = (name: string): any | undefined => {
  // console.log(JSON.parse(localStorage.getItem(name)));
  return JSON.parse(localStorage.getItem(name) || "{}") as any;
};
