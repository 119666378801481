import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Flex, Grid, Text, Input } from 'theme-ui';
import Iframe from 'react-iframe';
import { Ultilities } from 'utils/utilities';

function MiniBrowserComp({ url, setUrl }) {
  const css = `/* Hide scrollbar for Chrome, Safari and Opera */
  .myIframe::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .myIframe {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: scroll;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: scroll;
  }`;

  const ref = useRef(null);

  useEffect(() => {
    document.title = 'Mini Browser';
  });
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <html style={{ height: '100%' }}>
        <head>
          <title>Mini Browser</title>
          <base href={url.host} target='_parent'></base>
        </head>
        <style>{css}</style>
        <body style={{ height: '100%' }}>
          <Iframe
            title='Name'
            url={url}
            width='100%'
            id={url}
            className='myIframe'
            height='100%'
            onLoad={() => {
              // alert(document.getElementsByTagName("iframe")[2].contentWindow);
            }}
          />
        </body>
      </html>
    </Box>
  );
}

function MiniBrowser({ url, setUrl }) {
  return (
    <Box sx={{ width: '100%', height: '100%', mx: '0' }}>
      <Input
        sx={{ width: '100%', height: '25px', p: '0px' }}
        onChange={(e) => {
          const newUrl = e.target.value;
          if (Ultilities.validURL(newUrl) && newUrl.includes('http')) {
            setUrl(newUrl);
          } else if (Ultilities.validURL('http://' + newUrl)) {
            setUrl('http://' + newUrl);
          } else if (Ultilities.validURL('https://' + newUrl)) {
            setUrl('https://' + newUrl);
          }
        }}
      />
      <Flex sx={{ gap: '2px', width: '100%' }}>
        {/* <Button
          onClick={() => {
            console.log("try to go back");

            const iframe = document.getElementById(url) as HTMLIFrameElement;
            if (iframe) {
              console.log("iframe found:", iframe.contentWindow.location);
              // console.log(iframe.contentWindow.history);

              // iframe.contentWindow.history.go(-1); // back
              return;
            }
            console.log("iframe not found");
          }}
        >
          {"<"}
        </Button> */}
        <Text variant='title.content'>{url}</Text>
        {/* <Button
          onClick={() => {
            const iframe = document.getElementById(url) as HTMLIFrameElement;
            if (iframe) {
              iframe.contentWindow.history.go(1); // forward
            }
          }}
        >
          {">"}
        </Button> */}
      </Flex>

      <MiniBrowserComp url={url} setUrl={undefined} />
    </Box>
  );
}

function MultiBrowser() {
  const [url_1, setUrl_1] = useState('https://tcinvest.tcbs.com.vn');
  const [url_2, setUrl_2] = useState('https://ifin.tvsi.com.vn/');
  const [url_3, setUrl_3] = useState('https://zingnews.vn/');
  const [url_4, setUrl_4] = useState('https://fireant.vn/');

  const [isMobileLayout, setIsMobileLayout] = useState(true);
  return (
    <Container sx={{ bg: 'transparent', position: 'relative' }}>
      <Button
        sx={{
          position: 'absolute',
          width: '52px',
          height: '52px',
          top: '0',
          right: '0',
          zIndex: '2000',
        }}
        onClick={() => {
          setIsMobileLayout(!isMobileLayout);
        }}
      ></Button>
      <Flex
        sx={{
          width: '100%',
          height: '96vh',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: 'scroll',
          position: 'relative',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          p: '0px',
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Grid
          gap={'0px'}
          columns={
            isMobileLayout
              ? ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']
              : ['1fr', '1fr 1fr']
          }
          sx={{ width: '100%', height: '100%' }}
        >
          <MiniBrowser url={url_4} setUrl={setUrl_4}></MiniBrowser>
          <MiniBrowser url={url_1} setUrl={setUrl_1}></MiniBrowser>
          <MiniBrowser url={url_2} setUrl={setUrl_2}></MiniBrowser>
          <MiniBrowser url={url_3} setUrl={setUrl_3}></MiniBrowser>
          {/* <MiniBrowser url={url_5} setUrl={setUrl_5}></MiniBrowser> */}
        </Grid>
      </Flex>
    </Container>
  );
}
export default MultiBrowser;
