/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */
import produce, { Draft } from "immer";
import { customLog } from "utils/log";
import { IReduxAuthState, IReduxBaseAction } from "../../interface/IReduxBase";
import {
  USER_CHECK,
  USER_CHECK_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_VERIFY_OTP,
  USER_LOGIN_VERIFY_OTP_ERROR,
  USER_LOGIN_VERIFY_OTP_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_SUCCESS,
} from "./types";

export const initialState = {
  error: null,
  pending: false,
  user: null,
};

const myReducer = (
  state: IReduxAuthState = initialState,
  action: IReduxBaseAction
) =>
  produce(state, (draft: Draft<IReduxAuthState>) => {
    // console.log("[reducer]: produce: ", action);
    switch (action?.type) {
      case USER_CHECK:
        draft.pending = true;
        draft.error = null;
        draft.user = null;
        break;
      case USER_CHECK_SUCCESS:
        draft.pending = false;
        draft.error = false;
        draft.user = action.payload;
        break;
      case USER_LOGIN_SUCCESS:
        draft.pending = false;
        draft.error = null;
        draft.user = action.payload;
        break;
      case USER_LOGIN_ERROR:
        draft.pending = false;
        draft.error = null;
        draft.user = null;
        break;
      case USER_LOGOUT_SUCCESS:
        draft.pending = false;
        draft.error = null;
        draft.user = null;
        break;
      case USER_LOGOUT_FAILURE:
        draft.pending = false;
        draft.error = null;
        draft.user = null;
        break;
      case USER_LOGIN_VERIFY_OTP_SUCCESS:
        draft.pending = false;
        draft.error = false;
        draft.user = action.payload.user;
        break;
      case USER_LOGIN_VERIFY_OTP_ERROR:
        draft.pending = false;
        draft.error = true;
        break;
      default:
    }
  });

export default myReducer;
