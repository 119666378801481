/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from "axios";
import { store } from "index";
import { userLogout, userRefreshToken } from "redux/auth/action";
import { getStorage, tokenKey, tokenRefresh } from "./localStorageUtil";
import { Ultilities } from "./utilities";

const customAxios = axios.create();
export const baseURL = process.env.REACT_APP_API_BASE_URL || "";
export const basePath = process.env.REACT_APP_API_PREFIX || "";
export const apiURI = baseURL + basePath;

customAxios.interceptors.request.use(async (req: AxiosRequestConfig) => {
  const origin = Ultilities.getOrigin(req.url);
  if (!req.url?.startsWith("http") && !req.url?.startsWith("//")) {
    if (req.url?.startsWith("/")) {
      req.url = Ultilities.replaceHost(baseURL + req.url);
    } else {
      req.url = Ultilities.replaceHost(`${apiURI}/${req.url}`);
    }
  }
  req.url = Ultilities.replaceHost(`${req.url}`);

  const token = await getStorage(tokenKey);
  const isOurAPI = req.url?.startsWith(baseURL);
  // if (token && isOurAPI) {
  //   if (!req.headers) {
  //     req.headers = {};
  //   }
  //   req.headers.Authorization = `Bearer ${token}`;
  // }
  // req.headers["Referrer-Policy"] = "no-referrer";
  req.headers["Origin"] = window.location.hostname;
  // req.headers["Referer"] = "https://ifin.tvsi.com.vn";
  req.headers["Content-Type"] = "text/plain";
  return req;
});

// customAxios.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const { dispatch } = store;
//     const refereshToken = getStorage(tokenRefresh);

//     if (err.response.status === 401 && !err.response.config.url.includes('/auth/refresh-token') && refereshToken) {
//       dispatch(
//         userRefreshToken({
//           refresh_token: refereshToken,
//         }),
//       );
//     } else if (
//       err.response.status === 401 &&
//       !err.response.config.url.includes('/reset-password') &&
//       !err.response.config.url.includes('/login') &&
//       !err.response.config.url.includes('app/items')
//     ) {
//       dispatch(userLogout());
//     }
//     return Promise.reject(err);
//   },
// );

export default customAxios;

// import axios from 'axios';
// import { getStorage, tokenKey } from './localStorageUtil';

// export const baseURL = process.env.REACT_APP_API_BASE_URL || '';
// export const basePath = process.env.REACT_APP_API_PREFIX || '';

// export const apiURI = baseURL + basePath;

// type OptionsProp = {
//   params?: any;
//   body?: any;
//   method?: any;
// };

// async function http<T>(url: string, options: OptionsProp): Promise<T> {
//   if (!url) {
//     return Promise.reject(new Error('url is required!'));
//   }

//   let requestURI = url;

//   if (!url.startsWith('http') && !url.startsWith('//')) {
//     if (url.startsWith('/')) {
//       requestURI = baseURL + url;
//     } else {
//       requestURI = `${apiURI}/${url}`;
//     }
//   }

//   let response;

//   const client: any = axios.create();
//   const token: any = await getStorage(tokenKey);

//   if (token) {
//     client.defaults.headers.common.Authorization = `Bearer ${JSON.parse(token)}`;
//   }

//   switch (options.method) {
//     case 'POST':
//       response = await client.post(requestURI, options.body);
//       break;
//     default:
//       response = await client.get(requestURI, { params: options.params });
//       break;
//   }

//   if (response.status === 200) {
//     return Promise.resolve(response);
//   }

//   return Promise.reject(response);
// }

// export default http;

// curl 'https://realtime-ifin.tvsi.com.vn/markethub/negotiate?negotiateVersion=1' \
//   -X 'POST' \
//   -H 'sec-ch-ua: " Not A;Brand";v="99", "Chromium";v="99", "Google Chrome";v="99"' \
//   -H 'Referrer-Policy: no-referrer' \
//   -H 'sec-ch-ua-mobile: ?1' \
//   -H 'User-Agent: Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.83 Mobile Safari/537.36' \
//   -H 'Access-Control-Allow-Origin: https://ifin.tvsi.com.vn' \
//   -H 'Accept: */*' \
//   -H 'Referer: https://lemanhlinh.me/' \
//   -H 'DNT: 1' \
//   -H 'sec-ch-ua-platform: "Android"' \
//   --compressed
