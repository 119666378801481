import React, { useEffect } from "react";
import { Box, Flex, Heading } from "theme-ui";

function BlankPage({ title, details }: { title?: string; details?: string }) {
  useEffect(() => {
    document.title = "Le Manh Linh";
  });
  return (
    <Flex
      sx={{
        height: "100vh",
        pt: "200px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Heading
        variant="header1"
        sx={{ mt: "40px", p: "auto", textAlign: "center" }}
      >
        {title || "Not Found"}
      </Heading>
      <Box
        variant="text.header5"
        sx={{ mt: "40px", mb: "auto", p: "auto", textAlign: "center" }}
      >
        {details || "Page Not Found"}
      </Box>
    </Flex>
  );
}
export default BlankPage;
