// import { BaseAction } from 'redux/types';
// import { call, put, takeLatest } from "redux-saga/effects";
// import { customLog } from "utils/log";
// import {
//   USER_CHECK,
//   USER_LOGIN,
//   USER_LOGIN_VERIFY_OTP,
//   USER_LOGOUT,
//   USER_TOKEN_REFRESH,
// } from "./types";
// import authProvider from "../../apis/auth";
// import { IReduxBaseAction } from "../../interface/IReduxBase";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

// function* userLoginVerifyOtp(action: IReduxBaseAction) {
//   const { payload, meta } = action;
//   const { success, failure } = meta;
//   try {
//     const resp: ResponseGenerator = yield call(authProvider.verifyOtp, payload);
//     yield put({ meta, type: success, payload: resp });
//   } catch (error: any) {
//     yield put({ meta, type: failure, error: error?.message });
//   }
// }

// function* userCheck(action: IReduxBaseAction) {
//   const { meta } = action;
//   const { success, failure } = meta;
//   try {
//     const resp: ResponseGenerator = yield call(authProvider.getUser);
//     if (!resp) {
//       customLog("no user: ", meta);
//       yield put({ meta, type: success, error: true });
//     } else {
//       customLog("user found in saga: ", resp);
//       yield put({ meta, type: success, payload: resp });
//     }
//   } catch (error: any) {
//     yield put({ meta, type: failure, error: error?.message });
//   }
// }

// function* userLogin(action: IReduxBaseAction) {
//   const { meta, payload } = action;
//   const { success, failure } = meta;
//   try {
//     const response: ResponseGenerator = yield call(authProvider.login, payload);
//     if (!response) {
//       yield put({ meta, type: success, payload: response });
//     } else {
//       yield put({ meta, type: failure, payload: response.data });
//     }
//   } catch (error: any) {
//     yield put({ meta, type: failure, error: error?.message });
//   }
// }

// function* userLogout(action: IReduxBaseAction) {
//   const { meta } = action;
//   const { success, failure } = meta;
//   try {
//     const resp: ResponseGenerator = yield call(authProvider.logout);
//     yield put({ meta, type: success, payload: resp });
//   } catch (error: any) {
//     yield put({ meta, type: failure, error: error?.message });
//   }
// }

// function* userReferesh(action: IReduxBaseAction) {
//   const { meta, payload } = action;
//   const { success, failure } = meta;
//   try {
//     const resp: ResponseGenerator = yield call(
//       authProvider.refereshToken,
//       payload
//     );
//     yield put({ meta, type: success, payload: resp.data });
//   } catch (error: any) {
//     yield call(authProvider.logout, true);
//     yield put({ meta, type: failure, error: error?.message });
//   }
// }

function* authSaga() {
  // yield takeLatest(USER_LOGIN_VERIFY_OTP, userLoginVerifyOtp);
  // yield takeLatest(USER_LOGIN, userLogin);
  // yield takeLatest(USER_CHECK, userCheck);
  // yield takeLatest(USER_LOGOUT, userLogout);
  // yield takeLatest(USER_TOKEN_REFRESH, userReferesh);
}

export default authSaga;
