import { Theme } from "theme-ui";

export const importedTheme: Theme = {
  colors: {
    text: "#595959",
    background: "#fff",
    primary: "#1D1D44",
    highlight: "transparent",
    brandPrimary: "#EED478",
    brandSecondary: "#3E42B6",
  },
  text: {
    header: {
      fontFamily: "Work Sans",
      color: "#023047",
      fontWeight: 800,
      fontSize: "32px",
      textAlign: "left",
    },
    header1: {
      fontFamily: "Work Sans",
      color: "#023047",
      fontWeight: 600,
      fontSize: ["22px", "24px", "28px", "38px"],
      lineHeight: "60px",
    },
    header2: {
      fontFamily: "Work Sans",
      color: "#023047",
      fontWeight: 600,
      fontSize: ["20px", "22px", "26px", "32px"],
    },
    header3: {
      fontFamily: "Work Sans",
      color: "#4A4A68",
      fontWeight: 600,
      fontSize: ["18px", "20px", "22px", "26px"],
      lineHeight: "32px",
    },
    header4: {
      fontFamily: "Work Sans",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "140%",
      color: "#023047",
      textAlign: "left",
    },
    header5: {
      fontFamily: "Work Sans",
      fontWeight: 600,
      fontSize: ["14px", "16px", "18px"],
      lineHeight: "22px",
    },
    pretitle: {
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "14px",
      color: "#979797",
      textAlign: "center",
    },
    title: {
      normal: {
        fontFamily: "Work Sans",
        color: "#023047",
        fontWeight: 600,
        fontSize: ["60px", "80px"],
        lineHeight: "100.24px",
        textAlign: "center",
        letterSpacing: "-1%",
      },
      italic: {
        fontFamily: "Work Sans",
        color: "#023047",
        fontStyle: "italic",
        fontWeight: 600,
        fontSize: ["60px", "80px"],
        lineHeight: "100.24px",
        textAlign: "center",
        letterSpacing: "-1%",
      },
      content: {
        fontFamily: "Space Grotesk",
        fontWeight: 500,
        fontSize: ["10px", "16px"],
        lineHeight: "32px",
      },
    },
    contentItalic: {
      fontFamily: "Space Grotesk",
      fontWeight: 500,
      fontSize: ["11px", "13px", "16px", "18px"],
      lineHeight: "32px",
    },
    sub: {
      fontFamily: "Space Grotesk",
      color: "#023047",
      fontWeight: 500,
      fontSize: "21px",
      lineHeight: "32px",
      textAlign: "center",
    },
    viewAll: {
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "14px",
      color: "#3E42B6",
    },
    deal: {
      userInfo: {
        fontFamily: "Work Sans",
        fontSize: "14px",
        lineHeight: "19px",
        fontWeight: 500,
        color: "#4A4A68",
      },
    },
    userType: {
      fontFamily: "Work Sans",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 700,
      color: "#CAA937",
    },
    bodyText: {
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "16px",
      color: "#023047",
      textAlign: "center",
    },
    bodyBold: {
      fontFamily: "Work Sans",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 700,
      color: "#000000",
    },
    bodySmall: {
      fontFamily: "Work Sans",
      fontSize: "16px",
      lineHeight: "140%",
      fontWeight: 500,
      color: "#4A4A68",
    },
    bodySecondary: {
      fontFamily: "Work Sans",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 500,
      color: "#979797",
    },
    bodySecondarySmall: {
      fontFamily: "Work Sans",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 700,
      color: "#979797",
    },
    brandSecondary: {
      fontFamily: "Work Sans",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#3E42B6",
    },
    ssp_700_28_68: {
      fontFamily: "Work Sans",
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: 700,
      color: "#4A4A68",
    },
    ssp_600_28_47: {
      fontFamily: "Work Sans",
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#023047",
    },
    ws_700_16_68: {
      fontFamily: "Work Sans",
      fontSize: "16px",
      lineHeight: "140%",
      fontWeight: 700,
      color: "#4A4A68",
    },
    ws_700_12_00_05: {
      fontFamily: "Work Sans",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 700,
      color: "rgba(0, 0, 0, 0.5)",
    },
    ws_500_16_47: {
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "140%",
      color: "#023047",
    },
    ws_500_14_97: {
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#979797",
      textAlign: "left",
    },
    ws_400_16_50: {
      fontFamily: "Work Sans",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      color: "#D65050",
    },
    ws_400_16_68: {
      fontFamily: "Work Sans",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "140%",
      color: "#4A4A68",
    },
    ssp_600_40_47: {
      fontFamily: "Work Sans",
      color: "#023047",
      fontWeight: 600,
      fontSize: "40px",
      lineHeight: "50px",
    },
    ssp_600_21_68: {
      fontFamily: "Work Sans",
      color: "#4A4A68",
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: "27px",
    },
  },
  links: {
    appLink: {
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "16px",
      color: "#3E42B6",
    },
    /**
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  font-family: "Work Sans";
  display: block;
  text-align: left; */
    nav: {
      px: ["12px", "32px"],
      py: 0,
      m: "0",
      color: "#fff",
      // fontFamily: "Work Sans",
      // fontWeight: 700,
      fontSize: ["14px", "20px", "20px", "20px"],
      backgroundColor: "#2b2b2b",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      textAlign: "center",
      verticalAlign: "middle",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // lineHeight: 1,
      ":hover": {
        color: "#2b2b2b",
        backgroundColor: "#FFC905",
      },
    },
  },
  forms: {
    // checkbox: {},
    input: {
      fontFamily: "Fira Sans",
      height: "56px",
      border: "1px solid #BFBFBF",
      background: "#fff",
      color: "#1D1D44",
      "&:focus-visible": {
        outline: "none",
        border: "1px solid #1D1D44",
      },
      "::placeholder": {
        color: "#BFBFBF",
      },
    },
    label: {
      fontWeight: 500,
      marginBottom: "10px",
      fontSize: "17px",
      color: "#242424",
    },
    inputTitle: {
      marginBottom: "10px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
    },
    search: {
      fontFamily: "Work Sans",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "140%",
      color: "#023047",
      "::placeholder": {
        fontFamily: "Work Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "140%",
        color: "#979797",
      },
    },
  },
  buttons: {
    normal: {
      cursor: "pointer",
      fontFamily: "Fira Sans",
      fontWeight: 500,
      background: "transparent",
      color: "#4B4EB4",
      border: "1px solid #4B4EB4",
      minWidth: "130px",
      height: "52px",
      borderRadius: "12px",
      "&:disabled": {
        opacity: "0.5",
      },
    },
    link: {
      cursor: "pointer",
      fontFamily: "Fira Sans",
      background: "none",
      color: "#282828",
      padding: 0,
      height: "52px",
      "&:hover": {
        textDecoration: "underlined",
      },
    },
    image: {
      cursor: "pointer",
      padding: 0,
      background: "transparent",
    },
    navLink: {
      cursor: "pointer",
      color: "#023047",
      fontWeight: 700,
      fontFamily: "Work Sans",
      backgroundColor: "transparent",
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      textDecorationLine: "none",
      "&:hover": {
        // bg: 'text',
      },
    },
    appPrimary: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "14px",
      background: "#3E42B6",
      borderRadius: "100px",
      color: "#ffffff",
      height: "52px",
      minWidth: ["60px", "90px", "100px", "130px"],
      textTransform: "uppercase",
      "&:disabled": {
        opacity: "0.5",
      },
    },
    appPrimaryReversed: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "14px",
      background: "#DADBFA",
      borderRadius: "100px",
      color: "#3E42B6",
      textTransform: "uppercase",
      minWidth: ["60px", "90px", "100px", "130px"],
      "&:disabled": {
        opacity: "0.5",
        background: "#F1F5F8",
        borderRadius: "100px",
        color: "#8C8CA1",
      },
      "&:not(:disabled):hover": {
        textDecoration: "underline",
      },
    },
    appPrimaryReversedNoBackground: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "14px",
      background: "transparent",
      borderRadius: "100px",
      color: "#3E42B6",
      textTransform: "uppercase",
      minWidth: ["60px", "90px", "100px", "130px"],
      "&:disabled": {
        opacity: "0.5",
        background: "transparent",
        borderRadius: "100px",
        color: "#8C8CA1",
      },
      "&:not(:disabled):hover": {
        textDecoration: "underline",
      },
    },
    noBackground: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "16px",
      color: "#3E42B6",
      background: "transparent",
    },
    noBackgroundLight: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "16px",
      color: "#023047",
      background: "transparent",
    },
    noBackgroundWithBorder: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "16px",
      color: "#3E42B6",
      borderRadius: "999px",
      border: "1px solid #3E42B6",
      background: "transparent",
    },
    listingFilter: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "140%",
      color: "#4A4A68",
      borderRadius: "100px",
      p: "10px 20px",
      mx: "8px",
      border: "2px solid #D5D3D1",
      background: "transparent",
    },
    listingFilterActive: {
      cursor: "pointer",
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "140%",
      color: "#4A4A68",
      borderRadius: "100px",
      p: "10px 20px",
      mx: "8px",
      border: "2px solid #EED478",
      background: "#F8EEC9",
    },
  },
  layout: {
    container: {
      // maxWidth: ["540px", "720px", "960px", "1840px"],
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  styles: {
    a: {
      fontFamily: "Work Sans",
      fontWeight: 700,
      fontSize: "16px",
      color: "#3E42B6",
    },
  },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 99999,
    },
  },
  cards: {
    homeScreen: {
      width: "288.75px",
      height: "361px",
      borderRadius: "16px 0px",
      boxShadow: "0px 4px 21px rgba(105, 88, 26, 0.15)",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  breakpoints: ["820px", "1080px", "1140px", "1920px"],
};
