import { getNegotiate } from "apis";
import useAnalyticsEventTracker from "app/useAnalyticsEventTracker";
import {
  CompanyView,
  ProjectView,
  NameView,
  SkillView,
} from "components/HomeComponents";
import { IStock } from "interface/IStock";
import { parse } from "path";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { json } from "stream/consumers";
import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Heading,
  Paragraph,
  Image,
  Card,
  Input,
  Button,
  Textarea,
} from "theme-ui";
import { parseJsonText } from "typescript";
import { getStorage, handleStorage } from "utils/localStorageUtil";
import { Ultilities } from "utils/utilities";
import { LoadingView } from "components/LoadingView";

var XMLParser = require("react-xml-parser");

console.log(
  new XMLParser()
    .parseFromString(
      `<?xml version="1.0" encoding="utf-8" standalone="yes"?>
<!--For reference only. Only one request every 5 minutes!-->
<ExrateList>
  <DateTime>1/19/2023 3:51:27 PM</DateTime>
  <Exrate CurrencyCode="NOK" CurrencyName="NORWEGIAN KRONER    " Buy="-" Transfer="2,310.56" Sell="2,408.98" />
  <Exrate CurrencyCode="RUB" CurrencyName="RUSSIAN RUBLE       " Buy="-" Transfer="324.73" Sell="359.53" />
  <Exrate CurrencyCode="SAR" CurrencyName="SAUDI RIAL          " Buy="-" Transfer="6,233.37" Sell="6,483.41" />
  <Exrate CurrencyCode="SEK" CurrencyName="SWEDISH KRONA       " Buy="-" Transfer="2,220.99" Sell="2,315.59" />
  <Exrate CurrencyCode="SGD" CurrencyName="SINGAPORE DOLLAR    " Buy="17,278.31" Transfer="17,452.84" Sell="18,015.09" />
  <Exrate CurrencyCode="THB" CurrencyName="THAILAND BAHT       " Buy="626.26" Transfer="695.85" Sell="722.59" />
  <Exrate CurrencyCode="USD" CurrencyName="US DOLLAR           " Buy="23,270.00" Transfer="23,300.00" Sell="23,620.00" />
  <Source>Joint Stock Commercial Bank for Foreign Trade of Vietnam - Vietcombank</Source>
</ExrateList>`
    )
    // .children[0].chidren
    // ["chidren"]
    .getElementsByTagName("Exrate")
    .filter((xml) => {
      return xml.attributes.CurrencyCode === "USD";
    })
    .map((xml) => xml.attributes)
  // .getElementsByTagName("children")
);

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

function parseText(text, setJSON, setError) {
  try {
    console.log(text);
    const json = JSON.parse(text);

    if (json) {
      setJSON(json);
    }
  } catch (err) {
    try {
      const json_xml = new XMLParser()
        .parseFromString(text)
        .getElementsByTagName("Exrate")
        .filter((xml) => {
          return xml.attributes.CurrencyCode === "USD";
        })
        .map((xml) => xml.attributes);
      console.log(json_xml);
      if (json_xml) {
        setJSON(json_xml);
      } else {
        setError(err);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
const sample = `{
  "ap": 12500.0,
  "v": 1000,
  "cp": 100.0,
  "rcp": 0.008,
  "a": "BU",
  "type": "SHEEP",
  "n": 1,
  "t": "14:29:41"
}`;
export default function JSONViewer() {
  const modelTextAreaRef = useRef(null);

  const [history, setHistory] = useState([]);
  const [err, setErr] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [text, setText] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [loadingUrl, setLoadingUrl] = useState("");
  const [json, setJSON] = useState(undefined);
  const [isLoadingJson, setIsLoadingJson] = useState(false);

  useEffect(() => {
    document.title = "JSON Viewer Online";
  });
  useEffect(() => {
    parseText(text, setJSON, setErr);
  }, [text]);

  useEffect(() => {
    const newURL = Ultilities.replaceHost(loadingUrl);
    console.log("loading url");

    if (newURL) {
      setIsLoadingJson(true);
      fetch(Ultilities.replaceHost(loadingUrl))
        .then((res) => res.text())
        .then((json) => {
          //console.log(JSON.stringify(json));
          console.log(json);
          setText(json);
        })
        .catch((err) => {
          alert(`error: ${err}`);
        })
        .finally(() => {
          setIsLoadingJson(false);
        });
    }
  }, [loadingUrl]);

  return (
    <Container sx={{ bg: "transparent", position: "relative" }}>
      <Flex
        sx={{
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "scroll",
          position: "relative",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          p: "24px 32px",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Heading variant="header1">JSON Viewer</Heading>
        <Box sx={{ width: "80%" }}>
          <Text sx={{ width: "100px" }}>JSON url: </Text>
          <Flex
            sx={{
              width: "100%",
              height: "32px",
              py: "32px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              id={"textbox2"}
              name={"json"}
              sx={{ height: "42px" }}
              onChange={(e) => {
                const newText = e.target.value;
                setSourceUrl(newText);
              }}
            />
            <Button
              title="View"
              sx={{ minWidth: "100px", mx: "12px", height: "42px" }}
              onClick={(e) => {
                console.log("clicked");

                setLoadingUrl(sourceUrl);
              }}
            >
              Load
            </Button>
          </Flex>
          <Box sx={{ width: "100px", mt: "12px" }}>
            <Text>JSON source: </Text>
          </Box>
          <Box sx={{ width: "100%", py: "22px", mb: "32px" }}>
            <Textarea
              value={text}
              id={"textbox"}
              name={"json"}
              onChange={(e) => {
                const newText = e.target.value;
                setText(newText);
              }}
              role="textbox"
              aria-multiline="true"
              sx={{
                borderRadius: "8px",
                backgroundColor: "transparent",
                border: "2px solid #2b2b2b",
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "20px",
                lineHeight: "140%",
                height: `52px`,
                maxWidth: "100%",
                minWidth: "100%",
                mb: "8px",
              }}
            />
          </Box>
          <Flex>
            <Button
              title="View"
              sx={{ width: "100px", mr: "12px" }}
              onClick={(e) => {
                parseText(text, setJSON, setErr);
              }}
            >
              View {historyIndex}
            </Button>

            <Button
              title="View"
              sx={{ width: "100px", mr: "12px" }}
              onClick={(e) => {
                setHistory(history.concat(text));
                setText("");
              }}
            >
              Clear
            </Button>

            <Button
              title="View"
              sx={{ width: "100px", mr: "12px" }}
              onClick={(text) => {
                const currentIndex = historyIndex;
                setHistoryIndex(historyIndex + 1);
                if (history.length > currentIndex + 1) {
                  setText(history[currentIndex + 1]);
                }
              }}
            >
              Undo
            </Button>

            <Button
              title="View"
              sx={{ width: "100px", mr: "12px" }}
              onClick={(text) => {
                const currentIndex = historyIndex;
                setHistoryIndex(historyIndex - 1);
                if (
                  history.length < currentIndex - 1 &&
                  currentIndex - 1 >= 0
                ) {
                  setText(history[currentIndex - 1]);
                }
              }}
            >
              Redo
            </Button>
          </Flex>
        </Box>
        <Heading variant="header3" sx={{ width: "100px", mt: "32px" }}>
          Viewer
        </Heading>
        {isLoadingJson && <LoadingView />}
        {/* {!json && <Text>Not a JSON!!?</Text>} */}
        {json && (
          <ReactJson
            src={json}
            style={{ width: "80%", padding: "24px 32px", fontSize: "20px" }}
          />
        )}
      </Flex>
    </Container>
  );
}
