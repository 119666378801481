import { getNegotiate } from "apis";
import useAnalyticsEventTracker from "app/useAnalyticsEventTracker";
import {
  CompanyView,
  ProjectView,
  NameView,
  SkillView,
} from "components/HomeComponents";
import { IStock } from "interface/IStock";
import { parse } from "path";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { json } from "stream/consumers";
import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Heading,
  Paragraph,
  Image,
  Card,
  Input,
  Button,
  Textarea,
} from "theme-ui";
import { parseJsonText } from "typescript";
import { getStorage, handleStorage } from "utils/localStorageUtil";
import { Ultilities } from "utils/utilities";
import { LoadingView } from "components/LoadingView";
import moment from "moment-timezone";
import { timing } from "react-ga";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import countryTimezone from "country-timezone";
import SelectBox from "components/SelectBox";
import { utimes } from "fs";

function parseText(text, setJSON, setError) {
  try {
    const json = JSON.parse(text);
    if (json) {
      setJSON(json);
    }
  } catch (err) {
    setError(err);
  }
}
export default function CalculatorPage() {
  const modelTextAreaRef = useRef(null);

  return (
    <Container
      sx={{
        bg: "transparent",
        width: "100%",
        height: "100vh",
        position: "relative",
        m: "24px 0",
      }}
    >
      <Flex
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: "column",
          overflow: "scroll",
          position: "relative",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          overflowY: "scroll",
          // m: "24px 32px",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Heading variant="header1">Useful Widget</Heading>

        <Grid
          gap="10px"
          columns={[
            "1fr",
            "1fr 1fr",
            "1fr 1fr",
            "1fr 1fr 1fr",
            "1fr 1fr 1fr 1fr",
          ]}
          sx={{
            // width: "100%",
            // height: "100%",
            // px: ["12px", "32px", "82px", "105px"],
            // m: "0px",
            // mt: "20px",
            m: "20px 0px 10px 0px",
            // overflow: "scroll",
          }}
        >
          <TimeIntervalToTimeCard />
          <TimeZoneConvertorCard />
          <GrossToNetCard />
        </Grid>
      </Flex>
    </Container>
  );
}

function TimeIntervalToTimeCard() {
  const [timeInterval, setTimeInterval] = useState(1665885600000);

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading as={"h2"} sx={{ mb: "22px" }}>
        Time Interval To Date
      </Heading>
      <Card
        sx={{
          borderRadius: "8px",
          border: "2px solid rgba(0, 128, 0)",
          p: "12px",
          height: ["162px"],
          width: ["370px"],
          position: "relative",
        }}
      >
        <Flex
          sx={{
            // width: "100%",
            mx: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Flex sx={{ ml: "12px", mr: "32px", width: "110px" }}>
            timeInterval:{" "}
          </Flex>
          <Input
            sx={{ height: "32px", mx: "12px" }}
            onChange={(value) => {
              const newVal = value.target.value;

              if (Ultilities.isInt(newVal)) {
                setTimeInterval(parseInt(newVal));
              } else {
                setTimeInterval(null);
              }
            }}
          ></Input>
        </Flex>
        <Flex
          sx={{
            m: "8px",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Flex sx={{ mx: "12px", my: "2px" }} variant="text.header5">
            {moment(timeInterval).format("MMMM Do YYYY, h:mm:ss a")}
          </Flex>
        </Flex>
        {/* <Flex
        sx={{
          m: "8px",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Flex sx={{ mx: "12px" }}>{`parsing: ${timeInterval}`}</Flex>
      </Flex> */}
        <Flex
          sx={{
            m: "8px",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Flex sx={{ mx: "12px" }}>
            Ex: timeInterval = 1665885600000, then the date is <br /> October
            16th 2022, 9:00:00 am
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}

function CurrentDateTime({ timeZone }) {
  return (
    <Flex
      sx={{
        ml: "12px",
        // mr: "32px",
        width: "130px",
        textAlign: "right",
        variant: "text.header5",
      }}
    >
      {moment().tz(timeZone).format("MMM Do\n h:mm a")}
    </Flex>
  );
}

function TimeZoneConvertorCard() {
  const [reloadTime, setReloadTime] = useState(0);
  const [timezone, setTimezone] = useState("Asia/Colombo");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setReloadTime(reloadTime + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [reloadTime]);

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading as={"h2"} sx={{ mb: "22px" }}>
        Timezone To Date
      </Heading>
      <Card
        sx={{
          borderRadius: "8px",
          border: "2px solid rgba(0, 128, 0)",
          p: "12px",
          // height: ["180px"],
          // width: ["980px"],
          position: "relative",
        }}
      >
        <Flex
          sx={{
            width: "100%",
            // mx: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SelectBox
            id="status"
            disableFormik={true}
            loadOption={async (searchText) => {
              return Promise.resolve(
                Ultilities.timezones
                  .filter((tz) => {
                    // console.log(tz, searchText.toLowerCase());

                    return (
                      tz.toLowerCase().includes(searchText.toLowerCase()) ||
                      searchText === undefined
                    );
                  })
                  .map((tz) => {
                    return { id: tz, value: tz };
                  })
              );
            }}
            placeholder="India Kadil"
            label=""
            valueExpr="id"
            // defaultValue={{ id: 0, value: "All" }}
            displayExpr="value"
            isSearchable={true}
            onChange={(e) => {
              // console.log(e.status.id);
              setTimezone(e.status.id);
            }}
            // isMulti={true}
            // sx={{ width: "200px" }}
          />
          {reloadTime > 0 &&
            Ultilities.timezones.filter((tz) => {
              return tz.toLowerCase().includes(timezone.toLowerCase());
            }).length > 0 && <CurrentDateTime timeZone={timezone} />}
          {/* {JSON.parse(Ultilities.timezones)[0] + "dd"} */}
          {/* <Flex sx={{ ml: "12px", mr: "32px", width: "110px" }}>
            {countryTimezone.getTimezones("India")}
          </Flex> */}
        </Flex>
        <Flex
          sx={{
            m: "8px",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {reloadTime > 0 && (
            <Flex sx={{ mx: "12px", my: "2px" }} variant="text.header5">
              <br />
              Location Time: <br />
              {moment().format("MMMM Do YYYY, h:mm:ss a")}
            </Flex>
          )}
        </Flex>
      </Card>
    </Flex>
  );
}

function GrossToNetCard() {
  const [timeInterval, setTimeInterval] = useState(1665885600000);
  const [timezone, setTimezone] = useState("Asia/Colombo");
  const timezonesUrl =
    "https://raw.githubusercontent.com/frankzickert/world-clock/master/src/timezones.json";
  const countriesUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading as={"h2"} sx={{ mb: "22px" }}>
        Timezone To Date
      </Heading>
      <Card
        sx={{
          borderRadius: "8px",
          border: "2px solid rgba(0, 128, 0)",
          p: "12px",
          // height: ["180px"],
          // width: ["980px"],
          position: "relative",
        }}
      >
        <Flex
          sx={{
            width: "100%",
            // mx: "8px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SelectBox
            id="status"
            disableFormik={true}
            loadOption={async (searchText) => {
              return Promise.resolve(
                Ultilities.timezones
                  .filter((tz) => {
                    // console.log(tz, searchText.toLowerCase());

                    return (
                      tz.toLowerCase().includes(searchText.toLowerCase()) ||
                      searchText === undefined
                    );
                  })
                  .map((tz) => {
                    return { id: tz, value: tz };
                  })
              );
            }}
            placeholder="India Kadil"
            label=""
            valueExpr="id"
            // defaultValue={{ id: 0, value: "All" }}
            displayExpr="value"
            isSearchable={true}
            onChange={(e) => {
              // console.log(e.status.id);
              setTimezone(e.status.id);
            }}
            // isMulti={true}
            // sx={{ width: "200px" }}
          />
          {Ultilities.timezones.filter((tz) => {
            return tz.toLowerCase().includes(timezone.toLowerCase());
          }).length > 0 && <CurrentDateTime timeZone={timezone} />}
          {/* {JSON.parse(Ultilities.timezones)[0] + "dd"} */}
          {/* <Flex sx={{ ml: "12px", mr: "32px", width: "110px" }}>
            {countryTimezone.getTimezones("India")}
          </Flex> */}
        </Flex>
        <Flex
          sx={{
            m: "8px",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Flex sx={{ mx: "12px", my: "2px" }} variant="text.header5">
            <br />
            Location Time: <br />
            {moment().format("MMMM Do YYYY, h:mm a")}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
