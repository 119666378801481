import useAnalyticsEventTracker from "app/useAnalyticsEventTracker";
import {
  CompanyView,
  ProjectView,
  NameView,
  SkillView,
} from "components/HomeComponents";
import { useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Image,
} from "theme-ui";

function BuuukExperience({ company, duration }) {
  return (
    <CompanyView company={company} duration={duration}>
      <Paragraph variant="header4" sx={{ textAlign: "left" }}>
        <Box variant="text.header3" sx={{ pb: "14px" }}>
          Project:
        </Box>
        <ProjectView
          projectName="myENV app"
          role="Responsibilities: main responsible for iPhone/iPad app."
          exp="– The official app of Singapore’s National Environment Agency. The app
      is “the one-stop in Singapore for environmental, water and food
      information” "
          link="Appstore: https://apps.apple.com/sg/app/myenv/id444435182"
          appIcon="/img/myENV.jpeg"
        />
        <ProjectView
          projectName="Parousya SDK"
          role="Responsibilities: maintain iOS SDK."
          exp="– The SDK provides the integration for iOS platform. Parousya SDK is
      system-as-a-service platform, it is a solution for using iBeacon to
      automatically manage and control sessions for many business model. Ex:
      taxi trip."
          link="Github: https://github.com/parousya/Parousya-SAAS-iOS-SDK"
          appIcon=""
        />
        <ProjectView
          projectName="First Luxury app"
          role="Responsibilities: main responsible for iPhone/iPad app."
          exp="- The First
    Luxury app is an e-commerce application, to make the buying and selling
    of luxury items to be a smart, transparent, trustworthy and simple
    process!"
          link="Appstore: https://apps.apple.com/us/app/first-luxury/id1544728743"
          appIcon="/img/FL.jpeg"
        />
        <ProjectView
          projectName="Steer app"
          role="Responsibilities: main responsible for iPhone/iPad app."
          exp="- A car rental application for Goldbell Car Rental Ltd"
          link="Appstore: https://apps.apple.com/sg/app/st33r-car-rental-subscription/id1537759256"
          appIcon="/img/steer.jpeg"
        />
        <ProjectView
          projectName="Hafary app"
          role="Responsibilities: support maintaining and adding features"
          exp="- An application for tiles, surfaces and bathroom products shopping."
          link="Appstore: https://apps.apple.com/us/app/hafary/id1451162915"
          appIcon="/img/hafary.jpeg"
        />
      </Paragraph>
    </CompanyView>
  );
}

function TeeCoinExperience({ company, duration }) {
  return (
    <CompanyView company={company} duration={duration}>
      <Paragraph variant="header4" sx={{ textAlign: "left" }}>
        <Box variant="text.header3" sx={{ pb: "14px" }}>
          Project:
        </Box>
        <ProjectView
          projectName="Chattee app"
          role="Responsibilities: Implement main features: Payment, purchase/redeem coupon, find and review nearby merchants,…"
          exp="- The application help users spend Tee coin (TEC), a cryptocurrency, in hundreds of merchants in Singapore and Japan that have the collaboration with TeeCoin. "
          link="Appstore: https://apps.apple.com/us/app/chattee/id1437130675"
          appIcon="/img/chattee.jpeg"
        />
        <ProjectView
          projectName="Chattee Shop app"
          role="Responsibilities: implement features: payment, withdraw."
          exp="-  The payment application for merchants."
          link="Appstore: https://apps.apple.com/us/app/chattee/id1437130917"
          appIcon="/img/chatteeshop.jpeg"
        />
      </Paragraph>
    </CompanyView>
  );
}

function ExecutionLabExperience({ company, duration }) {
  return (
    <CompanyView company={company} duration={duration}>
      <Paragraph variant="header4" sx={{ textAlign: "left" }}>
        <Box variant="text.header3" sx={{ pb: "14px" }}>
          Project:
        </Box>
        <ProjectView
          projectName="Asukabu app"
          role="Responsibilities: implement features: user’s board (group) discussion, learning stock, converse logic from Objective C code."
          exp="- An app that users forecast tomorrow’s stock price and discuss their ideas."
          link="Appstore: https://apps.apple.com/jp/app/あすかぶ-株の初心者もゲーム感覚で投資がわかる株アプリ/id910158115"
          appIcon="/img/asukabu.jpeg"
        />
        <ProjectView
          projectName="KaruFX app"
          role="Responsibilities: implement features: learning stock."
          exp="- An app for both novice and experienced traders to forecast price movement as though it were a game, do virtual trade, and talk with other users about Forex."
          link="Appstore: https://apps.apple.com/jp/app/かるfx-fxを楽しく学べるfx-アプリ/id1046444798"
          appIcon="/img/karu_fx.jpeg"
        />

        <ProjectView
          projectName="Bitoco app "
          role="Responsibilities: implement features: user’s board (group) discussion, learning stock, market cap charts…."
          exp="- An app for both novice and experienced traders to forecast cryptocurrency price movement as though it were a game, do virtual trade, and talk with other users about cryptocurrency (Bitcoin). Key Achievements:  
          • Design app follow MVVM, Redux model, work with REST API, JSON parsing, handle data model….
          • Converse app logic from Objective-C to Swift. "
          link="Appstore: https://apps.apple.com/jp/app/びっとこ/id1345801258"
          appIcon=""
        />
      </Paragraph>
    </CompanyView>
  );
}

function ExperienceView() {
  return (
    <>
      <Heading
        variant="header"
        sx={{
          fontSize: ["48px"],
          textAlign: "left",
          p: ["14px", "28px 0px 0px 0px", "78px 0px 0px 0px"],
        }}
      >
        Personal Experience
      </Heading>
      <Grid
        gap="32px"
        columns={["1fr"]}
        sx={{ p: "0px 14px", flexDirection: "column", alignItems: "left" }}
      >
        <BuuukExperience company="Buuuk Ltd." duration="April 2020 - Now" />
        <TeeCoinExperience
          company="TEECOIN Ltd."
          duration="Sept. 2018 - April 2020"
        />
        <ExecutionLabExperience
          company="Execution Lab"
          duration="July 2017 - June 2018"
        />
      </Grid>
    </>
  );
}

export default function HomePage() {
  const gaEventTracker = useAnalyticsEventTracker("Home Page");
  gaEventTracker("call");

  useEffect(() => {
    document.title = "Profile";
  });

  return (
    <Container sx={{ bg: "transparent", position: "relative" }}>
      <Flex
        sx={{
          width: "100vw",
          minWidth: "100vw",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "scroll",
          position: "relative",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Grid
          gap="80px"
          columns={["1fr", "4fr 8fr"]}
          sx={{
            width: "100%",
            height: "100%",
            // px: ["12px", "32px", "82px", "105px"],
            m: "0px",
            p: "0px 0px 40px 0px",
          }}
        >
          <Box
            id="column-main-1"
            sx={{
              height: "100%",
              background:
                "-webkit-linear-gradient(94.05deg, #272736 -2.25%, #050512 100%)",
              p: "32px 22px",
            }}
          >
            <Box
              sx={{
                height: "100%",
                maxWidth: "443px",
                pl: "auto",
                pr: "0px",
                mr: "0px",
                ml: "auto",
              }}
            >
              <NameView />
              <SkillView />
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              maxWidth: "996px",
              px: "0px",
              mx: "0px",
            }}
          >
            <ExperienceView />
          </Box>
        </Grid>
      </Flex>
    </Container>
  );
}
