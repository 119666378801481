import {
  createChart,
  ColorType,
  LastPriceAnimationMode,
  BaselineSeriesOptions,
} from "lightweight-charts";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Box } from "theme-ui";

export const ChartComponent = (props) => {
  const {
    data,
    baseLine,
    sx,
    colors: {
      backgroundColor = "white",
      lineColor = "#2962FF",
      textColor = "black",
      areaTopColor = "rgb(255, 25, 0)",
      areaTopColor2 = "rgb(255, 25, 0, 0.28)",
      areaBottomColor = "rgba(41, 98, 255)",
      areaBottomColor2 = "rgba(41, 98, 255, 0.28)",
    },
  } = props;

  const chartContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current?.clientWidth,
        height: chartContainerRef.current?.clientHeight,
      });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: props.width || chartContainerRef?.current?.clientWidth,
      height: props.height || chartContainerRef?.current?.clientHeight,
      rightPriceScale: {},
      localization: {
        priceFormatter: (priceValue) => {
          return priceValue.toLocaleString();
        },
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        tickMarkFormatter: (time, tickMarkType, locale) => {
          const date = moment.unix(time);
          const timeString = moment(date).format("HH:mm");
          return timeString;
        },
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        horzTouchDrag: false,
        vertTouchDrag: false,
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
    });
    chart.timeScale().fitContent();
    chart.timeScale().applyOptions({
      visible: false,
      // tex
    });
    chart.priceScale().applyOptions({
      entireTextOnly: true,
      visible: false,
    });
    const newSeries = chart.addBaselineSeries({
      bottomFillColor2: areaBottomColor2,
      topFillColor2: areaTopColor2,
      topFillColor1: areaTopColor,
      bottomFillColor1: areaBottomColor,
      lastPriceAnimation: LastPriceAnimationMode.Continuous,
      baseValue: { type: "price", price: baseLine || 0 },
    });
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    props.width,
    props.height,
    baseLine,
    areaBottomColor2,
    areaTopColor2,
  ]);

  return (
    <Box
      sx={{ width: "320px", height: "128px", ...sx }}
      ref={chartContainerRef}
    />
  );
};
