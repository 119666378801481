import React from "react";
import { Flex, Spinner } from "theme-ui";

export function LoadingView({ sx }: { sx?: any }) {
  return (
    <Flex
      sx={{
        width: sx?.width || "100%",
        height: sx?.height || "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </Flex>
  );
}
