/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */

import { useFormikContext } from "formik";
import { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { Box, Flex, Label, Select, Text } from "theme-ui";

function Input(props) {
  return <Input {...props} />;
}

function SelectBox({
  id,
  isMulti,
  disableFormik,
  loadOption,
  label,
  placeholder,
  bottomHint,
  valueExpr,
  defaultValue,
  displayExpr,
  onChange,
  isInsideAnother,
  isSearchable,
  sx,
}: {
  id: string;
  isMulti?: boolean;
  disableFormik?: boolean;
  label?: string;
  placeholder?: string;
  bottomHint?: string;
  loadOption?: any;
  valueExpr: string;
  defaultValue?: any;
  displayExpr: string;
  onChange?: any;
  isInsideAnother?: boolean;
  isSearchable?: boolean;
  sx?: any;
}) {
  const { errors, touched, setValues, values } = !disableFormik
    ? useFormikContext<any>()
    : {
        errors: [],
        touched: [],
        setValues: (value) => {
          onChange(value);
        },
        values: [],
      };

  const promiseOptions = () => loadOption().then((res) => res);
  const promiseOptionsWithCallback = useCallback(
    (inputText) => loadOption(inputText).then((res) => res),
    []
  );

  const renderSelect = () => {
    const indicator = (props: any) => {
      return (
        <Flex sx={{ position: "absolute", right: 0, top: "10px" }}>
          {/* <Box sx={{ marginLeft: '20px', borderLeft: '1px solid hsl(0, 0%, 80%)', height: '35px' }} /> */}
          <Box p="8px">
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              fill="hsl(0, 0%, 80%)"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </Box>
        </Flex>
      );
    };
    const indicatorReverse = (props: any) => {
      return (
        <Flex sx={{ position: "absolute", right: 0, top: "10px" }}>
          <Box p="8px">
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              fill="hsl(0, 0%, 80%)"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </Box>
          <Box
            sx={{
              marginRight: "10px",
              borderLeft: "1px solid hsl(0, 0%, 80%)",
              height: "35px",
            }}
          />
        </Flex>
      );
    };
    if (loadOption) {
      const controlBorder =
        errors[id] && touched[id] ? "2px solid red" : "2px solid #D4D3D1";

      return (
        <>
          <AsyncSelect
            cacheOptions
            isMulti={isMulti}
            placeholder={placeholder}
            defaultOptions
            loadOptions={
              isSearchable || false
                ? promiseOptionsWithCallback
                : promiseOptions
            }
            getOptionLabel={(e) => e[displayExpr]}
            getOptionValue={(e) => e[valueExpr]}
            isSearchable={isSearchable || true}
            styles={{
              option: (provided) => ({
                ...provided,
                minHeight: "52px",
                margin: "0px",
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "140%",
                color: "#1D1D44",
                backgroundColor: "#FEFDFA",
              }),
              input: (provided) => ({
                ...provided,
                // height: '52px',
                // margin: '2px',
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "140%",
                color: "#4A4A68",
                border: errors[id] && touched[id] ? "red" : "#4A4A68",
                // backgroundColor: '#FEFDFA',
              }),
              control: (provided, state) => ({
                ...provided,
                fontFamily: "Work Sans",
                border: isInsideAnother ? "none" : controlBorder,
                // border: 'none',
                borderRadius: "10px",
                boxShadow: isInsideAnother ? "none" : "inherit",
                m: "0px",
                minHeight: isInsideAnother ? "52px" : "56px",
                backgroundColor: "#FEFDFA",
              }),
              placeholder: (provided) => ({
                ...provided,
                fontFamily: "Work Sans",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "140%",
                color: "#4A4A68",
                // backgroundColor: '#FEFDFA',
              }),
            }}
            onChange={(e) =>
              setValues({
                ...values,
                [id]: e,
              })
            }
            value={values[id]}
            // onBlur={handleBlur}
            components={
              isInsideAnother
                ? {
                    IndicatorsContainer: indicatorReverse,
                    // Control: controlreplacement,
                  }
                : { IndicatorsContainer: indicator }
            }
          />
          {bottomHint && (
            <Text
              sx={{
                fontFamily: "Work Sans",
                fontWeight: 500,
                fontSize: "14px",
                color: "#979797",
              }}
            >
              {bottomHint}
            </Text>
          )}
        </>
      );
    }
    return <Select />;
  };

  return (
    <Box sx={{ width: "100%", backgroundColor: "#FEFDFA", ...sx }}>
      {label && (
        <Label
          variant="forms.inputTitle"
          sx={{
            color: errors[id] && touched[id] ? "red" : "#4A4A68",
            lineHeight: "22.4px",
          }}
        >
          {label}
        </Label>
      )}
      {renderSelect()}
      {errors[id] && touched[id] && (
        <Text sx={{ fontSize: "13px", color: "red" }}>{errors[id]} </Text>
      )}
    </Box>
  );
}

export default SelectBox;
