import { getIntradayChart } from "apis";
import moment from "moment";
import { useState, useEffect } from "react";
import { ChartComponent } from "./ChartComponent";
import { LoadingView } from "./LoadingView";

export default function IntradayChart({ symbol }) {
  const [chart, setChart] = useState([]);

  useEffect(() => {
    console.log(`LOADING CHART: ${symbol}`);
    getIntradayChart({ symbol })
      .then((intraday) => {
        console.log(`LOADED CHART: ${intraday.count}`);
        setChart(intraday);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {chart.length > 0 && (
        <ChartComponent
          data={chart.map((point) => {
            const date = moment(point.dt, "DD/MM HH:mm").toDate();
            const timestamp = parseInt(moment(date).format("X"));

            return {
              time: timestamp, //.format("YYYY-MM-DD HH:mm"),
              value: point.p,
            };
          })}
          baseLine={(() => {
            const point = chart[0];
            return point.p - point.cp !== 0 ? point.p - point.cp : undefined;
          })()}
          colors={{
            backgroundColor: "white",
            lineColor: "#2962FF",
            textColor: "black",
            areaBottomColor2: "rgb(255, 25, 0)",
            areaBottomColor: "rgb(255, 25, 0, 0.28)",
            areaTopColor2: "rgb(60, 179, 113, 0.28)",
            areaTopColor: "rgb(60, 179, 113)",
          }}
          sx={{ width: ["220px"], height: ["138px"] }}
        />
      )}
      {chart.length <= 0 && (
        <LoadingView sx={{ width: ["220px"], height: ["138px"] }} />
      )}
    </>
  );
}
