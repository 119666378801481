import { getIntradayChart, getNegotiate } from "apis";
import useAnalyticsEventTracker from "app/useAnalyticsEventTracker";
import { ChartComponent } from "components/ChartComponent";
import {
  CompanyView,
  ProjectView,
  NameView,
  SkillView,
} from "components/HomeComponents";
import { LoadingView } from "components/LoadingView";
import IChartPoint from "interface/IChartPoint";
import { IStock } from "interface/IStock";
import moment, { locale, locales } from "moment";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { json } from "stream/consumers";
import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Heading,
  Paragraph,
  Image,
  Card,
  Input,
  Button,
} from "theme-ui";
import { getStorage, handleStorage } from "utils/localStorageUtil";
import { Ultilities } from "utils/utilities";

export default function StockDetailsPage() {
  const [socketUrl, setSocketUrl] = useState(null);
  const [prevState, setPrevState] = useState("Uninstantiated");
  const [inputValue, setInputValue] = useState("");
  // const [stocks, setStock] = useState([]);

  const [symbols, setSymbols] = useState<string[]>([]);
  const [currentWL, setCurrentWL] = useState<IStock[]>([
    { stockSymbol: "HPG" },
    { stockSymbol: "VNM" },
  ]);
  const [chartRefWL, setChartRefWL] = useState<IStock[]>([]);
  const [oldWL, setOldWL] = useState<IStock[]>([]);
  const [charts, setIntradayCharts] = useState<{
    [symbol: string]: IChartPoint[];
  }>();

  const [scheduledJob, setScheduledJob] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const [didHandShake, setDidHandShake] = useState(false);
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    document.title = "My Watchlist";
  });
  useEffect(() => {
    const currentWL_ = getStorage("WL") || [];
    if (currentWL_.length > 0) {
      setCurrentWL(Ultilities.uniqBy(currentWL_, (stock) => stock.stockSymbol));
    }
    getNegotiate()
      .then((res) => {
        const token = res.connectionToken;
        setSocketUrl(`wss://realtime-ifin.tvsi.com.vn/markethub?id=${token}`);
      })
      .catch((error) => {
        setSocketUrl(
          `wss://realtime-ifin.tvsi.com.vn/markethub?id=JNO-UnO9-qZUecCKupc7Hg`
        );
      });
  }, []);

  function loadIntradayChart({ symbols }: { symbols: string[] }) {
    console.log("--------loading charts");
    Promise.all(
      symbols.map((symbol) => {
        return getIntradayChart({ symbol });
      })
    ).then((responses) => {
      const newCharts: { [symbol: string]: IChartPoint[] } = {};
      for (let i = 0; i < responses.length; i++) {
        const intraday = responses[i];
        newCharts[intraday.ticker] = intraday.data;
      }
      setIntradayCharts(newCharts);
    });
  }

  function loadAllCharts({ wl }: { wl: IStock[] }) {
    if (wl.length > 0) {
      loadIntradayChart({
        symbols: wl.map((stock) => stock.stockSymbol),
      });
    }
  }

  function sendSocketRequest(didHandShake) {
    if (didHandShake) {
      sendMessage(
        `{"arguments":["${currentWL
          .map((s) => s.stockSymbol)
          .join(
            ","
          )},VNM,NAB,SHS"],"invocationId":"0","target":"RegisShortStockInfo","type":1}`
      );
    }
  }

  useEffect(() => {
    console.log("WL changed: ", currentWL);

    handleStorage("WL", currentWL);
    if (currentWL.length === 0) {
      console.log(`current empty`);
      return;
    }
    // loadIntradayChart();
    // return;
    if (oldWL.length === currentWL.length) {
      for (let i = 0; i < oldWL.length; i++) {
        const stock = oldWL[i];
        const chart = (charts || {})[stock.stockSymbol || ""] || [];
        if (stock.stockSymbol !== currentWL[i].stockSymbol) {
          setOldWL(currentWL);
          loadAllCharts({ wl: currentWL });
          sendSocketRequest(didHandShake);
          break;
        } else if (chart !== undefined) {
          if (chart.length <= 0) {
            console.log(
              `finish compare: empty chart: ${stock.stockSymbol}-${chart}`
            );
            loadAllCharts({ wl: currentWL });
            sendSocketRequest(didHandShake);
            break;
          } else {
            const lastUpdated = moment(
              chart[chart.length - 1].dt,
              "DD/MM hh:mm"
            );
            const diffUpdateTime = moment().diff(lastUpdated, "minutes");
            const isTodayWeekend = [6, 0].includes(new Date().getDay());

            /* console.log(
              "lastUpdate: ",
              lastUpdated,
              chart[chart.length - 1].dt,
              "   difftime: ",
              diffUpdateTime,
              " isWeekend:",
              isTodayWeekend
            ); */
            const dt = chart[chart.length - 1].dt;
            if (
              !isTodayWeekend &&
              dt.slice(dt.length - 6, dt.length - 1) !== "14:59" &&
              diffUpdateTime > 0
            ) {
              console.log(
                `finish compare: old chart: ${moment().diff(
                  moment(chart[chart.length - 1].dt).format("dd/mm hh:mm"),
                  "minutes"
                )}-${currentWL}`
              );
              loadAllCharts({ wl: currentWL });
              sendSocketRequest(didHandShake);
              break;
            } else {
              console.log("NOT RELOAD CHART-------");
              if (!stock.matchPrice) {
                console.log("match: ", stock);

                sendSocketRequest(didHandShake);
                break;
              }
            }
          }
          console.log(
            `comparing: ${oldWL[i].stockSymbol}-${currentWL[i].stockSymbol}`
          );
        }
      }
    } else {
      console.log(`finish compare, wl changed: ${oldWL}-${currentWL}`);
      setOldWL(currentWL);
      loadAllCharts({ wl: currentWL });
      sendSocketRequest(didHandShake);
    }
  }, [currentWL]);

  useEffect(() => {
    switch (connectionStatus) {
      case "Closed":
        setDidHandShake(false);
        break;
      case "Open":
        if (prevState === "Open") {
          return;
        }
        setPrevState(connectionStatus);
        sendMessage(`{"protocol":"json","version":1}`);
        break;

      default:
      // sendMessage(`{"protocol":"json","version":1}`);
    }
  }, [connectionStatus, prevState, readyState, sendMessage]);

  useEffect(() => {});

  useEffect(() => {
    console.log(lastMessage);
    // if (lastMessage !== null) {
    //   switch (lastMessage.data) {
    //     case "{}":
    //       setInterval(function () {
    //         sendMessage(
    //           `{"arguments":["${currentWL
    //             .map((s) => s.stockSymbol)
    //             .join(
    //               ","
    //             )}"],"invocationId":"0","target":"RegisShortStockInfo","type":1}`
    //         );
    //       }, 10000);
    //       break;
    //     default:
    //       break;
    //   }
    // }
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));

      console.log(lastMessage.data);

      const msgs = lastMessage.data.replaceAll(``, ";").replaceAll("", ";");

      console.log("msgs: ", msgs);

      switch (lastMessage.data) {
        case "{}":
          setDidHandShake(true);
          // sendSocketRequest(true);
          if (!scheduledJob) {
            console.log("register Interval job: ----", scheduledJob);

            const id = setInterval(function () {
              sendMessage(
                `{"arguments":["${currentWL
                  .map((s) => s.stockSymbol)
                  .join(
                    ","
                  )},VNM,NAB,SHS"],"invocationId":"0","target":"RegisShortStockInfo","type":1}`
              );
            }, 5000);
            setScheduledJob({ id });
          }
          break;
        default:
          const loadedStocks: [IStock] = msgs
            .split(";")
            .map((jsonString) => {
              try {
                const processedString = lastMessage.data.replaceAll("", "");
                console.log(processedString);

                const json = JSON.parse(processedString).arguments?.[0] || [];
                console.log(json);

                return json;
              } catch (e) {
                console.log("Json parse error: ", e);
                return [];
              }
            })
            .reduce((res, next) => res.concat(next), []);
          if (loadedStocks) {
            console.log(`updated with:`, loadedStocks);
            if (loadedStocks.length > 0 && loadedStocks[0].flagATO) {
              //"flagATO exist:", loadedStocks[0]);
            } else {
              console.log("loaded Stocks:", loadedStocks);

              setCurrentWL(
                currentWL.map((stock) => {
                  let updatedInfo =
                    loadedStocks.filter((updatedStock: IStock) => {
                      return updatedStock.stockSymbol === stock.stockSymbol;
                    })?.[0] || {};
                  if (updatedInfo.flagATO) {
                    return { ...stock };
                  } else {
                    return { ...stock, ...updatedInfo };
                  }
                })
              );
            }
          } else {
          }
          break;
      }
    } else {
      console.log(
        `something is wrong with Socket------------lastMessage is ${lastMessage}`
      );
    }
    return () => {};
  }, [lastMessage, setMessageHistory]);

  return (
    <Container
      sx={{
        bg: "transparent",
        width: "100%",
        position: "relative",
        m: "24px 0",
      }}
    >
      <Flex
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "scroll",
          position: "relative",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          // m: "24px 32px",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Heading variant="header1">Watch List</Heading>

        <Flex
          sx={{
            // width: "100%",
            mx: "32px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text sx={{ width: "120px", mr: "12px" }}>STOCK: </Text>
          <Input
            sx={{ px: "20px", height: "42px" }}
            onChange={(value) => {
              console.log("...", value.target.value);

              setInputValue(value.target.value.toUpperCase());
            }}
          ></Input>
          <Button
            title="ADD"
            sx={{ width: "100px", height: "42px", mx: "20px" }}
            onClick={(text) => {
              let newWL = currentWL;
              if (
                newWL.filter(
                  (value) =>
                    inputValue.toUpperCase() === value.stockSymbol.toUpperCase()
                ).length > 0
              ) {
                return;
              }
              newWL.push({ stockSymbol: inputValue.toUpperCase() });
              setCurrentWL(newWL);
            }}
          >
            ADD
          </Button>
        </Flex>
        <Grid
          gap="10px"
          columns={[
            "1fr",
            "1fr 1fr",
            "1fr 1fr",
            "1fr 1fr 1fr",
            "1fr 1fr 1fr 1fr",
          ]}
          sx={{
            // width: "100%",
            // height: "100%",
            // px: ["12px", "32px", "82px", "105px"],
            // m: "0px",
            // mt: "20px",
            m: "20px 0px 10px 0px",
            // overflow: "scroll",
          }}
        >
          {/* <span>The WebSocket is currently {connectionStatus}</span>
          {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
          <ul>
            {messageHistory.map((message, idx) => (
              <span key={idx}>
                {message ? message.data : null}
                <br></br>
              </span>
            ))}
          </ul> */}
          {currentWL.map((stock) => {
            return (
              <Card
                key={stock.stockSymbol}
                sx={{
                  borderRadius: "8px",
                  border:
                    stock.priceChange < 0
                      ? "2px solid red"
                      : "2px solid rgba(0, 128, 0)",
                  p: "12px",
                  height: ["162px"],
                  width: ["370px"],
                  position: "relative",
                  // backgroundColor:
                  //   stock.priceChange < 0 ? "red" : "rgba(60, 224, 84, 0.8)",
                }}
              >
                <Flex
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    flexGrow: 100,
                  }}
                >
                  <PriceBox
                    stock={stock}
                    removeCb={(symbol) => {
                      const wl = currentWL.filter((stock) => {
                        return stock.stockSymbol !== symbol;
                      });
                      setCurrentWL(wl);
                    }}
                  />
                  {charts !== undefined && (
                    <Flex>
                      {charts[stock.stockSymbol || ""] !== undefined &&
                        charts[stock.stockSymbol || ""].length > 0 && (
                          <IntradayChart
                            chart={charts[stock.stockSymbol || ""]}
                          />
                        )}
                    </Flex>
                  )}
                  {(charts === undefined ||
                    charts[stock.stockSymbol || ""] === undefined ||
                    charts[stock.stockSymbol || ""].length <= 0) && (
                    <LoadingView sx={{ width: ["220px"], height: ["138px"] }} />
                  )}
                </Flex>
              </Card>
            );
          })}
        </Grid>
      </Flex>
    </Container>
  );
}

function PriceBox({ stock, removeCb }: { stock?: IStock; removeCb?: any }) {
  const navigate = useNavigate();
  const color =
    stock.priceChange < 0
      ? stock.matchPrice === stock.floorPrice
        ? "rgb(100, 186, 255)" // blue
        : "rgb(255, 0, 0)" //red
      : stock.priceChange > 0
      ? stock.matchPrice === stock.ceilingPrice
        ? "rgb(128, 0, 128)" //purple
        : "rgb(0, 128, 0)" //green
      : "rgb(250, 200, 0)"; // yellow
  return (
    <Flex
      sx={{
        flexDirection: "column",
        width: "120px",
        // height: "100%",
        minWidth: "90px",
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          flexGrow: 120,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "baseline",
          }}
        >
          <Heading variant="header" sx={{ fontSize: "22px" }}>
            {stock.stockSymbol}
          </Heading>
          <Heading
            variant="header"
            sx={{
              fontSize: "10px",
              pr: 12,
              color: color,
              maxWidth: "10px",
              px: "0",
              // px: ["4px", "14px", "24px"],
            }}
          >
            {`${
              stock.floorCode === "04"
                ? "UP"
                : stock.floorCode === "10"
                ? "VN"
                : "HNX"
            }`}
          </Heading>
          <Heading
            variant="header"
            sx={{
              fontSize: "20px",
              pr: 12,
              color: color,
              px: ["4px", "14px", "24px"],
            }}
          >
            {`   ${stock.matchPrice || "-"}`}
          </Heading>
        </Flex>

        <Heading variant="header" sx={{ color: color, fontSize: "15px" }}>
          {stock.matchPrice
            ? `${stock.priceChangePercent}% (${
                stock.priceChange > 0 ? "+" : ""
              }${stock.priceChange})`
            : "-"}
        </Heading>
        <Heading
          variant="header"
          sx={{ fontStyle: "italic", color: color, fontSize: "15px" }}
        >
          {stock.matchValue
            ? `Total value: ${Ultilities.toCurrencyFormat(
                stock.totalTradingValue *
                  (stock.floorCode === "10" ? 1000 : 1000000),
                { maximumFractionDigits: 1 }
              )}`
            : "-"}
        </Heading>
        <Text
          variant="contentItalic"
          sx={{
            fontSize: "13px !important",
            pl: 0,
            color: "gray",
            width: ["100px", "120px", "130px", "160px"],
          }}
        >
          updated {(stock.time || "-").slice(0, 5)}
        </Text>
      </Flex>
      <Flex
        sx={{
          width: "99%",
          py: "auto",
          flexGrow: 110,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex
          sx={{
            width: "22px",
            height: "22px",
            borderRadius: "999px",
            backgroundColor: "rgb(240, 45, 45, 0.8)",
            color: "white",
            fontSize: "12px",
            alignItems: "center",
            justifyContent: "center",
            px: "auto",
            // filter:
            //   "invert(95%) sepia(97%) saturate(14%) hue-rotate(213deg) brightness(104%) contrast(104%)",
          }}
          onClick={() => {
            removeCb(stock.stockSymbol || "");
          }}
        >
          <Image
            src={"icons/Minus_M-1.svg"}
            sx={{
              filter:
                "invert(95%) sepia(97%) saturate(14%) hue-rotate(213deg) brightness(104%) contrast(104%)",
              WebkitFilter:
                "invert(95%) sepia(97%) saturate(14%) hue-rotate(213deg) brightness(104%) contrast(104%)",
            }}
          ></Image>
        </Flex>
        <Flex
          sx={{
            height: "22px",
            borderRadius: "999px",
            backgroundColor: "rgb(45, 45, 45, 0.8)",
            color: "white",
            fontSize: "12px",
            alignItems: "center",
            justifyContent: "center",
            px: "8px",
          }}
          onClick={() => {
            // removeCb(stock.stockSymbol || "");
            navigate(`/wl/${stock.stockSymbol}`);
          }}
        >
          More Details
        </Flex>
      </Flex>
    </Flex>
  );
}

function IntradayChart({ chart }) {
  return (
    <ChartComponent
      data={chart.map((point) => {
        const date = moment(point.dt, "DD/MM HH:mm").toDate();
        const timestamp = parseInt(moment(date).format("X"));

        return {
          time: timestamp, //.format("YYYY-MM-DD HH:mm"),
          value: point.p,
        };
      })}
      baseLine={(() => {
        const point = chart[0];
        return point.p - point.cp !== 0 ? point.p - point.cp : undefined;
      })()}
      colors={{
        backgroundColor: "white",
        lineColor: "#2962FF",
        textColor: "black",
        areaBottomColor2: "rgb(255, 25, 0)",
        areaBottomColor: "rgb(255, 25, 0, 0.28)",
        areaTopColor2: "rgb(60, 179, 113, 0.28)",
        areaTopColor: "rgb(60, 179, 113)",
      }}
      sx={{ width: ["220px"], height: ["138px"] }}
    />
  );
}
