import { getNegotiate } from "apis";
import useAnalyticsEventTracker from "app/useAnalyticsEventTracker";
import {
  CompanyView,
  ProjectView,
  NameView,
  SkillView,
} from "components/HomeComponents";
import { IStock } from "interface/IStock";
import { parse } from "path";
import { useCallback, useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { json } from "stream/consumers";
import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Heading,
  Paragraph,
  Image,
  Card,
  Input,
  Button,
  Textarea,
} from "theme-ui";
import { parseJsonText } from "typescript";
import { getStorage, handleStorage } from "utils/localStorageUtil";

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

function parseTextToModel(text) {
  const json = JSON.parse(text);
  let result = "";
  let params = "";
  let initFunc = "";
  let initEmptyFunc = "";
  let initEmptyFuncFirstLine = "";

  result += `class JsonModel: Codable, JSONMappable {\n\n`;

  initFunc += `\n    required init(json: JSON) {\n`;
  initEmptyFuncFirstLine += `\n    init(`;

  const keys = Object.keys(json);
  for (let i = 0; i < keys.length; i++) {
    const item = { key: keys[i], value: json[keys[i]] };

    if (isInt(item.value)) {
      params += `    let ${item.key}: Int\n`;
      initFunc += `        self.${item.key} = json["${item.key}"].intValue\n`;

      initEmptyFuncFirstLine += `${item.key}: Int = 0, `;
      initEmptyFunc += `        self.${item.key} = ${item.key}\n`;
    } else if (isFloat(item.value)) {
      params += `    let ${item.key}: Double\n`;
      initFunc += `        self.${item.key} = json["${item.key}"].doubleValue\n`;

      initEmptyFuncFirstLine += `${item.key}: Double = 0, `;
      initEmptyFunc += `        self.${item.key} = ${item.key}\n`;
    } else {
      params += `    let ${item.key}: String\n`;
      initFunc += `        self.${item.key} = json["${item.key}"].stringValue\n`;

      initEmptyFuncFirstLine += `${item.key}: String = "", `;
      initEmptyFunc += `        self.${item.key} = ${item.key}\n`;
    }
    if (i === keys.length - 1) {
      initEmptyFuncFirstLine = initEmptyFuncFirstLine.slice(0, -2);
    }
  }

  initFunc += `    }\n`;
  initEmptyFuncFirstLine += `) {\n`;
  initEmptyFunc += `    }\n`;

  result += params + initFunc + initEmptyFuncFirstLine + initEmptyFunc + `}\n`;
  return result;
}

function parseText(text, setModel, setNoOfModelLines) {
  // console.log(result);
  const result = parseTextToModel(text);
  setModel(result);
  setNoOfModelLines((result.match(/\n/g) || []).length);
  return result;
}
const sample = `{
  "ap": 12500.0,
  "v": 1000,
  "cp": 100.0,
  "rcp": 0.008,
  "a": "BU",
  "type": "SHEEP",
  "n": 1,
  "t": "14:29:41"
}`;
export default function ParseJsonModel() {
  const modelTextAreaRef = useRef(null);

  const [text, setText] = useState("");
  const [noOfInputLines, setNoOfInputLines] = useState(3);
  const [model, setModel] = useState("");
  const [noOfModelLines, setNoOfModelLines] = useState(3);

  useEffect(() => {
    document.title = "JSON Model Parser";
  });
  useEffect(() => {
    // console.log("WL changed: ", currentWL);
    // eslint-disable-next-line eqeqeq
    // console.log("check");
    if (noOfModelLines > 3 && text !== sample) {
      // console.log("pass");
      modelTextAreaRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [noOfModelLines, model]);

  return (
    <Container sx={{ bg: "transparent", position: "relative" }}>
      <Flex
        sx={{
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "scroll",
          position: "relative",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          p: "24px 32px",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Heading variant="header1">JSONMappable Parser</Heading>
        <Grid gap={12} columns={["1fr"]} sx={{ width: "80%" }}>
          <Flex>
            <Button
              title="Load Sample"
              sx={{ mr: "12px", minWidth: "172px" }}
              onClick={(_) => {
                setText(sample);
                setNoOfInputLines(10);
                parseText(sample, setModel, setNoOfModelLines);
              }}
            >
              Load Sample
            </Button>
            <Button
              title="Clear"
              sx={{ mr: "12px", minWidth: "172px" }}
              onClick={(_) => {
                const sample = ``;
                setText(sample);
                setNoOfInputLines(3);
                setModel(sample);
                setNoOfModelLines(3);
              }}
            >
              Clear All
            </Button>
          </Flex>
          <Heading variant="header3">Web API Object Response:</Heading>

          <Textarea
            value={text}
            id={"textbox"}
            name={"json"}
            onChange={(newText) => {
              setText(newText.target.value);
              setNoOfInputLines(
                (newText.target.value.match(/\n/g) || []).length
              );
            }}
            role="textbox"
            aria-multiline="true"
            sx={{
              borderRadius: "10px",
              backgroundColor: "transparent",
              border: "2px solid #D5D3D1",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "140%",
              minHeight: `${noOfInputLines * 20 * 1.7}px`,
              maxWidth: "100%",
              minWidth: "100%",
              mb: "8px",
            }}
          />
          <Flex>
            <Button
              title="ADD"
              sx={{ mr: "12px", minWidth: "72px" }}
              onClick={(_) => {
                parseText(text, setModel, setNoOfModelLines);
              }}
            >
              PARSE
            </Button>
            <Button
              ref={modelTextAreaRef}
              title="ADD AND COPY TO CLIPBOARD"
              sx={{ mr: "12px", minWidth: "72px" }}
              onClick={(_) => {
                navigator.clipboard.writeText(
                  parseText(text, setModel, setNoOfModelLines)
                );
              }}
            >
              PARSE AND COPY TO CLIPBOARD
            </Button>
          </Flex>
          <Heading variant="header3">JSONMappale Model:</Heading>
          <Textarea
            value={model}
            sx={{
              minHeight: `${noOfModelLines * 20 * 1.6}px`,
              lineHeight: "140%",
              fontSize: "20px",
            }}
            onChange={(newText) => {
              setModel(newText.target.value);
            }}
          />
          <Button
            title="ADD"
            // sx={{ mr: "12px", minWidth: "72px" }}
            onClick={() => {
              navigator.clipboard.writeText(model);
            }}
          >
            COPY!
          </Button>
          <Flex variant="text.header5" sx={{ my: "32px" }}>
            <Text sx={{ mx: "12px" }}>About the JSONMappable: </Text>
            <a
              href="https://gist.github.com/lelinh/3eb4fb71455216340eb452a379d6bb61"
              target={"_blank"}
            >
              https://gist.github.com/lelinh/3eb4fb71455216340eb452a379d6bb61
            </a>
          </Flex>

          <Heading variant="header4">Example:</Heading>

          <Flex>
            <Text
              sx={{ minWidth: "100px", whiteSpace: "pre-wrap", mr: "32px" }}
            >
              {`Raw response:
  {
    "ap": 12500.0,
    "v": 1000,
    "cp": 100.0,
    "rcp": 0.008,
    "a": "BU",
    "type": "SHEEP",
    "n": 1,
    "t": "14:29:41"
  }`}
            </Text>
            <Text
              sx={{ minWidth: "100px", whiteSpace: "pre-wrap" }}
            >{`Swift Model:
${parseTextToModel(sample)}
`}</Text>
          </Flex>
        </Grid>
      </Flex>
    </Container>
  );
}
