/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import authReducer from './auth/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

const rootReducer = () => combineReducers({ auth: authReducer, toastr: toastrReducer });

export default rootReducer;
